<template>
  <div class="form-header center">
    <img class="logo" src="@/assets/small-logo.png" alt="logo" />
    <div class="title">
      <div>南京市市政设计研究院有限责任公司</div>
      <div>安全建议与隐患举报</div>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style lang="less" scoped>
.form-header {
  .logo {
    height: 120px;
    margin-right: 30px;
  }

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>