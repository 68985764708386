import request from '@/api/request'

export function fetchList(data) {
    return request({
        url: '/office-service/qualityInform/list',
        method: 'post',
        data,
    })
}
export function add(data) {
    return request({
        url: '/office-service/qualityInform/insert',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/office-service/qualityInform/update',
        method: 'post',
        data,
    })
}


export function fetchDetail(data) {
    return request({
        url: '/office-service/qualityInform/query/' + data.id,
    })
}

export function finish(data) {
    return request({
        url: '/office-service/qualityInform/replyInform',
        method: 'post',
        data,
    })
}

export function finishByAdmin(data) {
    return request({
        url: '/office-service/qualityInform/replyInformByCenter',
        method: 'post',
        data,
    })
}

export function fetchTable(data) {
    return request({
        url: '/office-service/qualityInform/queryCenterList',
        method: 'post',
        data,
    })
}


export function setTop(data) {
    return request({
        url: `/office-service/qualityInform/top/${data.id}/${data.isTop}`,
        method: 'post',
    })
}

export function close(data) {
    return request({
        url: `/office-service/qualityInform/close/${data.id}`,
        method: 'post',
    })
}

export function remove(data) {
    return request({
        url: `/office-service/qualityInform/delete/${data.id}`,
        method: 'post',
    })
}

export function recover(data) {
    return request({
        url: `/office-service/qualityInform/recover/${data.id}`,
        method: 'post',
    })
}