<template>
  <div>
    <Pane />

    <a-card class="container">
      <Header />

      <div class="detail">
        <a-skeleton :loading="loading" :paragraph="{ rows: 8 }">
          <div class="header">
            <a-tag color="green" v-if="detail.isPublic"> 公开 </a-tag>
            <a-tag color="red" v-else> 不公开 </a-tag>

            <div class="title">{{ detail.title }}</div>

            <div class="extra">
              <DataDictFinder
                dictType="informStatus"
                :dictValue="String(detail.status)"
                iconType="badge"
              />
            </div>
          </div>

          <div class="meta">
            <div class="left">
              <a-icon type="tag" class="icon" />
              <span class="value">
                <DataDictFinder
                  dictType="informType"
                  :dictValue="detail.type"
                />
              </span>
            </div>

            <div>
              <a-icon type="eye" class="icon" />
              <span class="value">{{ detail.readingTimes }}</span>
            </div>
            <div>
              <a-icon type="user" class="icon" />
              <span class="value">{{ detail.informerName }}</span>
            </div>
            <div>
              <a-icon type="clock-circle" class="icon" />
              <span class="value">{{ detail.createAt }}</span>
            </div>
          </div>

          <div class="content">
            {{ detail.content }}
          </div>

          <div class="images">
            <img
              v-for="(item, index) in images"
              :key="index"
              :src="item"
              alt=""
            />
          </div>

          <template
            v-if="
              Array.isArray(detail.recordList) && detail.recordList.length > 0
            "
          >
            <div class="list-title">处理记录</div>
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in detail.recordList"
                :key="index"
              >
                <div class="author">{{ item.creator }}：</div>
                <div class="res">{{ item.content }}</div>
              </div>
            </div>
          </template>

          <template v-if="isMe || isReportManager">
            <a-form :form="form" :colon="false" @submit="handleSubmit">
              <a-form-item :label="title">
                <a-textarea
                  v-decorator="[
                    'reply',
                    { rules: [{ required: true, message: '请输入！' }] },
                  ]"
                />
              </a-form-item>

              <div class="center" style="padding: 40px 0">
                <a-space>
                  <a-button
                    type="primary"
                    html-type="submit"
                    :loading="saveLoading"
                    >提交</a-button
                  >
                  <a-button @click="$router.go(-1)">关闭</a-button>
                </a-space>
              </div>
            </a-form>
          </template>
        </a-skeleton>
      </div>
    </a-card>
  </div>
</template>
  
<script>
import watermark from "@/mixins/watermark";
import Header from "./components/header.vue";
import { fetchDetail, finish, finishByAdmin } from "./api";

import { mapState, mapGetters } from "vuex";
export default {
  mixins: [watermark],

  components: {
    Header,
  },

  data() {
    return {
      loading: false,
      id: "",
      detail: {},
      form: this.$form.createForm(this),

      saveLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isReportManager"]),

    isMe() {
      return this.user.uuid === this.detail.informer;
    },

    title() {
      return this.isMe ? "意见反馈" : "处理意见";
    },
    images() {
      return this.detail.picture ? this.detail.picture.split("，") : [];
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    this.id = id;
    this.getDetail();
  },

  methods: {
    getDetail() {
      this.loading = true;
      fetchDetail({
        id: this.id,
      })
        .then((res) => {
          this.detail = res ?? {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;

          if (this.isMe) {
            finish({
              content: values.reply,

              pid: this.id,
            })
              .then(() => {
                this.form.resetFields();
                this.getDetail();
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else if (this.isReportManager) {
            finishByAdmin({
              content: values.reply,

              pid: this.id,
            })
              .then(() => {
                this.form.resetFields();
                this.getDetail();
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            alert("您没有权限处理这条工单");
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  max-width: 60vw;
  margin: 0 auto;
  padding: 16px 0 80px;
  border-top: 1px solid #f0f0f0;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .title {
      margin-left: 4px;
      font-weight: bold;
      font-size: 18px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .content {
    padding-bottom: 16px;
    color: #999;
  }

  .images {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      height: 240px;
      width: 240px;
      object-fit: cover;
    }
  }

  .meta {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #999;

    .value {
      margin-left: 4px;
    }
  }

  .list-title {
    border-top: 1px solid #f0f0f0;
    padding-top: 16px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 16px;
  }

  .list {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    gap: 1px;

    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px 0;
      background-color: #fff;

      .author {
        font-weight: bold;
      }
    }
  }
}
</style>
  